/* Общие стили */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bodyCont{
  padding: 10px 40px;
}
.dateNews{
  margin: 20px 0;
  color: rgba(0, 0, 0, 0.32);
}
.pdfMobile{
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  border: none;
  background-color: #94bdec;
}
.pdfMobile a{
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}
/* Стили для контейнера новостей */
.containerNews {
  margin: 5px auto;
  padding: 35px 45px;/* Уменьшаем отступы по бокам */
  max-width: 1150px;
  background-color: #f8f9fa;
  margin-top: 80px;
  border-radius: 15px;
}
.containerNews img{
  max-width: 1100px;
  max-height: 1000px;
  width: 100%;
  height: 100%;
}
/* Стили для каждой новости */
.news-item {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.bodyCont ul{
  margin-left: 20px;
  padding: 35px;
  border-radius: 15px;
  background-color: rgba(129, 177, 229, 0.13);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.bodyCont img{
  border-radius: 10px;
  margin: 20px 0;
}
/* Заголовок новости */
.news-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0D3E61;
}

/* Текст новости */
.news-text {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

/* Контейнер для PDF-файла */
.pdf-container {
  margin-bottom: 20px;
}

/* Форма комментария */
.comment-form {
  max-width: 600px;
  margin: 20px auto;
}

/* Поля ввода имени и электронной почты */
.name-email-container {
  display: flex;
  flex-wrap: wrap; /* Обертываем на мобильных устройствах */
  justify-content: space-between;
  margin-bottom: 20px;
}
.comments{
  margin-top: 30px;
  padding: 0 100px;
}
.comment{
  border-radius: 5px;
  background-color: rgba(196, 216, 243, 0.58);
  padding: 10px 10px 10px 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.userLetterIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 5px;
}
.comment p{
  color: #3b3b3b;
  word-wrap: break-word;
}
.name-input,
.email-input {
  width: 100%; /* Полное ширине на мобильных устройствах */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px; /* Увеличиваем отступ между полями */
}

.email-input {
  margin-left: 0; /* Убираем отступ слева для второго поля */
}

/* Кнопка отправки комментария */
.submit-button {
  width: 100%; /* Полная ширина на мобильных устройствах */
}

/* Поля ввода комментария */
.comment-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Учитываем отступы и рамку в общей ширине */
}

/* Кнопка отправки комментария */
.submit-button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  box-sizing: border-box; /* Учитываем отступы и рамку в общей ширине */
}

/* При наведении */
.submit-button:hover {
  background-color: #0056b3;
}
@media (max-width: 900px) {
  .comments{
    padding: 0;
  }
  .news-item{
    padding: 10px;
  }
  .containerNews{
    padding: 25px 25px;
  }
  .bodyCont{
    padding:10px 0;
  }

}
