/* App.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  main {
    flex: 1;
  }
.section-title {
  text-align: center;
  font-size: 36px;
  color: #0D3E61;
  margin-bottom: 40px;
}
.noData{
  color: #79797a;
}
  