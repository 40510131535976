.projects {
    padding: 85px 0;
    background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }
  .containerProjects {
    max-width: 1700px;
    margin: 0 50px;
    width: 90%;
  }
  .mapContainer{
    display: flex;
  }
  .mapContent{
    width: 100%;

  }
  .linkToMaps{
    text-decoration: none;
  }
  .mapRedirect{
    margin-top: 20px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: none;
    background-color: #94bdec;
    cursor: pointer;
    width: 100%;
  }
  .toProject{
    cursor: pointer;
    padding: 5px;
    text-align: center;
  }
  .toProject:hover{
    color: #1C5D99;
    border-radius: 5px;
  }
  .popUp{
    width: 200px;
    padding: 0;
  }
.diagramMaps{
  width: 300px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 35px;
  background-color: rgba(223, 234, 234, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  .filteredList{
    margin: 10px 0;
    color: #083250;
    max-height: 380px; /* Adjust the height as needed */
    overflow-y: auto;
  }

/* WebKit Browsers (Chrome, Safari) */
.filteredList::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  border-radius: 10px;
}

.filteredList::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

.filteredList::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.filteredList::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.leaflet-control-attribution{
  display: none;
}
/* Firefox */
.filteredList {
  padding: 1px;
  scrollbar-width: thin;/* Make scrollbar thinner */
  scrollbar-color: rgba(120, 157, 185, 0.48) rgba(175, 203, 241, 0); /* Color of the scrollbar thumb and track */
}



.filteredList_Object{
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
.filteredList_Object:hover{
  background-color: #e0e0e0;
}
.resetBtn{
  cursor: pointer;
  background: none;
  border: none;
}
.resetBtn img{
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease-in-out;
}
.spinning {
  animation: spin 0.5s linear ;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  .project {
    width: calc(33.3333% - 20px);
    background-color: #fff;
    padding: 30px;
    margin-bottom: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .project:hover {
    transform: scale(1.03);
  }
  
  .project-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .project-title {
    font-size: 24px;
    color: #0D3E61;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .project-description {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  .loaderArea{
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.loader {
  width: 108px;
  height: 108px;
  border: 10px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  height: 80vh; /* Высота карты для мобильных устройств */
}
@media (max-width: 768px) {
  .mapContainer {
    display: flex;
    flex-direction: column; /* Вертикальное выравнивание */
    align-items: center; /* Центрирование по горизонтали */
    height: auto;
  }
  .mapRedirect{
    width: 100%;
  }
  .leaflet-container {
    height: 40vh; /* Высота карты для мобильных устройств */
    width: 90vw; /* Ширина карты для мобильных устройств */
    margin-bottom: 20px; /* Отступ снизу для карты */
  }

  .diagramMaps {
    display: flex;
    flex-direction: column; /* Вертикальное выравнивание диаграммы и кнопки */
    align-items: center; /* Центрирование по горизонтали */
    width: 90vw; /* Ширина контейнера диаграммы */
    margin-top: 20px; /* Отступ сверху для диаграммы */
  }

  .filteredList {
    display: flex;
    flex-direction: column; /* Вертикальное выравнивание списка отфильтрованных объектов */
    align-items: center; /* Центрирование по горизонтали */
    width: 90vw; /* Ширина контейнера списка */
    margin-top: 20px; /* Отступ сверху для списка */
  }

  .filteredList_Object {
    margin: 10px 0; /* Отступы между элементами списка */
    text-align: center; /* Выравнивание текста по центру */
    width: 85%; /* Ширина элементов списка */
  }

  .loaderArea {
    display: flex;
    justify-content: center; /* Центрирование загрузчика по горизонтали */
    align-items: center; /* Центрирование загрузчика по верьикали */
    height: 100vh; /* Высота загрузчика на весь экран */
  }

  .resetBtn {
    position: fixed; /* Фиксированное позиционирование кнопки сброса */
    bottom: 20px; /* Отступ снизу */
    right: 20px; /* Отступ справа */
    z-index: 1000; /* Поверх остальных элементов */
    width: 50px; /* Ширина кнопки сброса */
    height: 50px; /* Высота кнопки сброса */
    background: white; /* Фоншвый цвет кнопки сброса */
    border: none; /* Удаление рамки */
    border-radius: 50%; /* Округление кнопки сброса */
    display: flex;
    justify-content: center; /* Центрирование иконки по горизонтали */
    align-items: center; /* Центрирование иконки по вертикали */
  }

  .resetBtn img {
    width: 24px; /* Ширина иконки сброса */
    height: 24px; /* Высота иконки сброса */
  }
}

  