/* Общие стили */
.investor-page {
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
  margin-top: 80px;
}

/* Навигационное меню */
.navigation {
  background: #1c1c1c;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.navigation li {
  margin: 0;
}

.navigation a {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  position: relative;
  transition: color 0.3s, transform 0.3s;
  border-radius: 30px;
  overflow: hidden;
}

.navigation a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navigation a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.navigation a:hover {
  transform: translateY(-2px);
  color: #f1c40f;
}

.navigation li.selected a {
  color: #f1c40f;
}

/* Секция ссылок */
.links-section {
  margin: 40px 0;
  text-align: center;
}

.links-section ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.links-section li {
  margin: 0;
}

.links-section a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.links-section a:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Главная секция */
.main-section {
  position: relative;
  text-align: center;
  color: white;
}

/* Контейнер изображения */
.image-container {
  position: relative;
}

.diagramList {
  margin-left: 45px;
}

/* Основное изображение */
.main-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Наложенный текст */
.overlay-text {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
}

.overlay-text p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Кнопка контактов */
.contact-button {
  background-color: #e74c3c;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #c0392b;
}

/* Информация о акциях */
.stock-info-box {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  width: 200px;
  padding: 15px;
  text-align: left;
  font-size: 14px;
  border-radius: 5px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stock-info-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
}

/* Секция финансовых результатов и пресс-релизов */
.financial-press-section {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  flex-direction: column;
  background-color: #ecf0f1;
}

.financial-results,
.press-releases {
  background-color: white;
  padding: 20px;
  margin-top: 40px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.financial-results h3,
.press-releases h3 {
  margin-top: 0;
}

.results-container {
  margin-top: 20px;
}

.results-container h4 {
  margin: 10px 0;
}

.results-container ul {
  list-style: none;
  padding: 0;
}

.results-container li {
  margin: 10px 0;
}

.more-button {
  background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.more-button:hover {
  background-color: #ffffff;
}

.press-releases ul {
  list-style: none;
  padding: 0;
}

.press-releases li {
  margin: 10px 0;
}
.diagramInvestor{
  width: 100%;
  height: 80%;
  position: relative;
}
/* Responsive Design */
@media (max-width: 1024px) {
  .overlay-text {
    left: 50%;
    width: 70%;
  }

  .stock-info-box {
    right: 10%;
  }
}

@media (max-width: 1060px) {
  .navigation ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .links-section ul {
    flex-direction: column;
    gap: 10px;
  }

  .overlay-text {
    width: 90%;
    left: 50%;
  }

  .stock-info-box {
    top: 70%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .financial-press-section {
    padding: 20px;
  }

  .financial-results,
  .press-releases {
    width: 100%;
    margin-top: 20px;
  }

  .main-image {
    height: 400px;
  }

  .diagramList {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .diagrams,
  .diagramListObject {
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 480px) {
  .navigation a {
    padding: 8px 16px;
  }

  .contact-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .more-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .overlay-text p {
    font-size: 16px;
  }
}
