body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}

.container-product {
  margin: 5px auto;
  padding: 0 15px;
  max-width: 1100px;
  margin-top: 80px;
}

.product-detail-card {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-detail-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0D3E61;
}

.product-detail-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.product-detail-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.product-detail-price {
  font-size: 1.8em;
  color: #e60023;
  margin-bottom: 30px;
  font-weight: bold;
}

.buy-button {
  background-color: #0D3E61;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
}

.buy-button:hover {
  background-color: #1C5D99;
}

@media (max-width: 768px) {
  .product-detail-card {
    padding: 20px;
    margin: 10px;
  }

  .product-detail-title {
    font-size: 2em;
  }

  .product-detail-description {
    font-size: 1em;
  }

  .product-detail-price {
    font-size: 1.5em;
  }

  .buy-button {
    padding: 10px 20px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .product-detail-card {
    padding: 10px;
    margin: 5px;
  }

  .product-detail-title {
    font-size: 1.8em;
  }

  .product-detail-description {
    font-size: 0.9em;
  }

  .product-detail-price {
    font-size: 1.3em;
  }

  .buy-button {
    padding: 10px;
    font-size: 0.9em;
  }
}
