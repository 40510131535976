

.containerInvestorItem {
    margin: 5px auto;
    padding: 35px 45px;/* Уменьшаем отступы по бокам */
    max-width: 1150px;
    background-color: #f8f9fa;
    margin-top: 20px;
    border-radius: 15px;
}
.containerInvestorItem img{
    max-width: 1100px;
    max-height: 1000px;
    width: 100%;
    height: 100%;
}
/* Стили для каждой новости */
.investor-item {
    margin: 0;
    padding: 40px;
    border-radius: 8px;
    background-color: #ecf0f1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.bodyCont ul{
    margin-left: 20px;
    padding: 35px;
    border-radius: 15px;
    background-color: rgba(129, 177, 229, 0.13);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.bodyCont img{
    border-radius: 10px;
    margin: 20px 0;
}
/* Заголовок новости */
.investor-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #0D3E61;
}

/* Текст новости */
.investor-text {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
}

/* Контейнер для PDF-файла */
.pdf-container {
    margin-bottom: 20px;
}