/* ContentComponent.css */
.content-container {
    padding: 10px;
  }
.content-container img{
  width: 100%;
}
  
  .content-container h1 {
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
  
  .content-container p {
    font-size: 16px;
    color: #666;
  }
  