.vacancy-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f4f4f9;
    padding: 20px;
    margin-top: 70px;
}

.vacancy-detail-card {
    background-color: #ffffff;
    color: #002f6c;
    border-radius: 12px;
    padding: 30px;
    width: 600px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.vacancy-detail-title {
    font-size: 2em;
    font-weight: bold;
    color: #002f6c;
}

.vacancy-detail-salary {
    font-size: 1.5em;
    font-weight: 600;
    color: #0056b3;
}

.vacancy-detail-heading {
    font-size: 1.3em;
    font-weight: 600;
    color: #002f6c;
}

.vacancy-detail-description,
.vacancy-detail-requirements {
    font-size: 1.1em;
    line-height: 1.6;
    color: #4b5563;
}