.services {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 60px;
  }
  
  .services-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    color: #0D3E61;
  }
  
  .services-list {
    display: grid;
    grid-gap: 20px;
  }
  
  .service {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  
  .service-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #0D3E61;
  }
  
  .service-description {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  .contact-form {
    margin-top: 40px;
  }
  
  .contact-title {
    margin-bottom: 20px;
  }
  
  .contact-input,
  .contact-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .contact-button:hover {
    background-color: #0056b3;
  }
  