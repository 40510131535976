.vacancies{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    background-color: #f4f4f9;
    padding: 10px;
}
.vacancies-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    background-color: #f4f4f9;
}

.vacancy-card {
    background-color: #ffffff;
    color: #002f6c;
    border-radius: 12px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.vacancy-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.vacancy-title {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #002f6c;
}

.salary {
    font-size: 1.3em;
    font-weight: 500;
    margin-bottom: 10px;
    color: #002f6c;
}

.description {
    font-size: 1em;
    line-height: 1.5;
    color: #333;
}