/* Общие стили */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Стили для секции новостей */
.news-section {
  padding: 40px;
  background-color: #e0e8f5;
  text-align: center;
}

.select-category{
  width: 100%;
}
/* Styling the select box */
/* CustomSelect.css */


body {
  background-color: #e0e8f5;
}
.news-date{
  position: absolute;
  right: 10px;
  bottom: 2px;
  color: rgba(0, 0, 0, 0.32);
}
.news-section h2 {
  font-size: 36px;
  font-weight: bold;
  color: #0D3E61;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}

.news-section h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #0D3E61;
  margin: 10px auto 0;
}

.news-cards-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px; /* Уменьшено значение gap между карточками */
  padding: 20px;
}

.news-card {
  background: linear-gradient(135deg, #e9edff, #ffffff);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease; /* Добавлено свойство transition для opacity */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px; /* Уменьшено значение верхнего отступа */
  opacity: 0; /* Начальное значение opacity для плавного появления */
}

.news-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.news-card.visible {
  opacity: 1;
}

.news-image {
  width: 100%;
  height: auto;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.news-content {
  padding: 20px;
  position: relative;
  z-index: 1;
}

.news-content::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit; /* Наследовать фон от родителя */
  filter: blur(8px);
  z-index: -1;
}

.news-card h3 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 24px;
  color: #0D3E61;
}

.news-card p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.6;
  color: #333;
}

/* Размеры для разных карточек */
.news-card.size-1 {
  grid-column: span 2;
  grid-row: span 1;
}

.news-card.size-2 {
  grid-column: span 2;
  grid-row: span 1;
}

.news-card.size-3 {
  grid-column: span 2;
  grid-row: span 2;
}

.news-card.size-4 {
  grid-column: span 4;
  grid-row: span 2;
}

.news-card.size-5 {
  grid-column: span 2;
  grid-row: span 2;
}

.news-card.size-6 {
  grid-column: span 6;
  grid-row: span 1;
}

/* Адаптивная верстка */
@media (max-width: 1200px) {
  .news-cards-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px; /* Уменьшено значение gap между карточками в адаптивной верстке */
  }

  .news-card h3 {
    font-size: 22px;
  }

  .news-card p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .news-section{
    padding: 15px;
  }
  .news-cards-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 5px; /* Уменьшено значение padding */
    gap: 5px; /* Уменьшено значение gap между карточками в мобильной верстке */
    justify-content: center;
  }

  .news-card {
    grid-column: span 1;
    margin-bottom: 10px; /* Уменьшено значение нижнего отступа */
    height: auto;
    justify-content: center;
    align-items: center;
    width:100%;
    animation: fadeIn 0.5s ease forwards; /* Добавлена анимация для появления */
  }

  .news-card.size-1,
  .news-card.size-2,
  .news-card.size-3,
  .news-card.size-4,
  .news-card.size-5,
  .news-card.size-6 {
    grid-column: span 1;
  }

  .news-image {
    height: auto;
  }

  .news-content {
    padding: 10px;
    text-align: center;
  }

  .news-card h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .news-card p {
    font-size: 14px;
  }
}

.load-more-btn {
  background-color: #0D3E61;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.load-more-btn:hover {
  background-color: #1C5D99;
}

/* Анимация появления */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
