.boezgrt-about-container {
  max-width: 1000px;
  margin: auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Стили заголовка */
.boezgrt-title {
  text-align: center;
  font-size: 2.8rem;
  color: #003366;
  margin-bottom: 30px;
  position: relative;
  font-weight: bold;
}

.boezgrt-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #ff9933;
  margin: 10px auto 0;
}

/* Основные стили текста */
.boezgrt-content {
  padding: 20px;
  line-height: 1.6;
}

.boezgrt-content p {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 20px;
  position: relative;
  padding: 15px;
  background: #e0e8f5;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, background 0.3s ease;
}

.boezgrt-content p:hover {
  transform: translateY(-5px);
  background: #e0e8f5;
}

.boezgrt-highlight {
  font-weight: bold;
  color: #25263e;
  background-color: #e1dff1;
  padding: 3px 5px;
  border-radius: 5px;
}

.boezgrt-content p:first-letter {
  font-size: 1.4rem;
  font-weight: bold;
  color: #003366;
}

/* Адаптивность */
@media (max-width: 768px) {
  .boezgrt-about-container {
    padding: 20px;
  }

  .boezgrt-title {
    font-size: 2.2rem;
  }

  .boezgrt-content p {
    font-size: 1rem;
    padding: 10px;
  }
}
  