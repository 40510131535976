.news-form {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  
  .news-form input,
  .news-form textarea {
    margin: 10px;
    padding: 10px;
    width: 80%;
    max-width: 600px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .news-form button {
    padding: 10px 20px;
    background-color: #0D3E61;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .news-form button:hover {
    background-color: #084B8A;
  }
  