.contacts {
    padding: 40px 20px;
    background-color: #f9fafb;
    margin-top: 80px;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  .typeOfApplication{
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
  }
  .radio-group label{
    margin-right: 15px;
  }
  .radio-group input{
    margin-right: 5px;
  }
  .section-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 36px;
    color: #0D3E61;
    font-weight: bold;
    position: relative;
  }
  
  .section-title::after {
    content: "";
    width: 60px;
    height: 4px;
    background: #007bff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .contact-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .contact-item {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
  }
  
  .contact-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
  
  .contact-item h3 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #0D3E61;
  }
  
  .contact-item p {
    font-size: 18px;
    color: #555;
  }
  
  .contact-item a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .contact-item a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  @media (max-width: 800px) {
    .radio-group{
      display: flex;
    }
  }