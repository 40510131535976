.footer {
  background-color: #1d3249;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.textSection {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  border-right: 1px solid #ffffff;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
}

.column {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerh3 {
  margin-bottom: 10px;
}

.text {
  max-width: 100%; /* Ensure text width is responsive */
  word-wrap: break-word;
}

.mapSectionFooter {
  width: 40%;
}

.mapContainerFooter {
  padding: 20px;
  width: 100%;
  background-color: rgba(44, 87, 105, 0.73);
  border-radius: 10px;
}

.addressText {
  margin-bottom: 10px;
  font-weight: bold;
}

.cartG {
  width: 100%;
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
  border: 5px solid white;
}

.contactIcons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.iconLink {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.phoneNumber {
  color: #fff;
}
.phoneNumber a{
  color: #699eda;
}

.workingHours {
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
}

.geologyText {
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
}

.list {
  padding-left: 20px;
}

/* Add styles for the logo container */
.logoContainer {
  background-color: rgba(255, 255, 255, 0.848); /* Semi-transparent grey background */
  padding: 20px;
  border-radius: 15px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  /* width: 100%; */
  max-width: 200px; /* Adjust the maximum width as needed */
  height: auto;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .textSection {
    flex-direction: column;
    width: 100%;
  }

  .leftColumn,
  .rightColumn {
    width: 100%;
    padding: 20px;
    border-right: none;
  }

  .mapSection {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
  .mapContainerFooter{
    width: 250%;
  }

  .logoContainer {
    margin-top: 20px; /* Add some margin to separate the logo container */
  }
}

@media (max-width: 1024px) {
  .logo{
    /* width: 100%; */
    max-width: 200px; /* Adjust the maximum width as needed */
    height: auto;
  }
}
@media (max-width: 1440px) {
  .logo{
    /* width: 100%; */
    max-width: 280px; /* Adjust the maximum width as needed */
    height: auto;
  }
}
